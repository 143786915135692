import { enrollmentSchema } from "../utils/enrollmentSchema"

let { parseFormField } = require("services/airtable")

export const useMentalHealthFormFields = ({ formFields }) => {
  return {
    sectionFormFields: parseFormField(formFields),
    validationSchema: enrollmentSchema({ fields: formFields }),
  }
}
