import axios from "axios"
import moment from "moment"

import { checkIfAddressIsUnique } from "../utils/checkIfAddressIsUnique"
import { getSignedInUser } from "../../Auth/services/user"
import { checkIfUserAlreadyExists } from "../../Auth/services/signup"
import { getUserAddresses, getUserData } from "../../Auth/services/signin"
import { isBrowser } from "../../../services/general"
import { uploadDocumentsToStorage } from "services/firebase/firebaseStorage"

import {
  GATSBY_WEBSITE_URL,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_USER_ROLE_ID,
  GATSBY_TELECONSULT_URL,
  GATSBY_CREATE_ENROLLMENT_RECORD,
} from "gatsby-env-variables"

import firebase from "firebase"

export const sendEnrollmentForm = async (config) => {
  let { errorCallback, callback, enrollmentData, dispatch } = config
  let {
    firstName,
    lastName,
    gender,
    sex,
    birthday,
    mobileNumber,
    department,
    employeeNumber,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactRelationship,
    address,
    // accessCode,
    company,
    site,
    employeeId,
  } = enrollmentData

  let emergencyContact = {
    name: emergencyContactName?.value || emergencyContactName,
    mobileNumber: emergencyContactNumber?.value || emergencyContactNumber,
    relationship:
      emergencyContactRelationship?.value || emergencyContactRelationship,
  }

  try {
    let { authUser, addresses, userData, accessCode } = getSignedInUser()

    if (
      process.env.GATSBY_AIRTABLE_WEBSITE_NAME ===
      "GEN Wellness Program Website"
    ) {
      accessCode = "GEN999MG"
    }

    console.log(accessCode)

    let email = authUser?.email
    let authUid = authUser?.uid
    let addressesId = addresses?.id || ""
    let addressesList = addresses?.addresses || []
    let userRoles =
      userData?.roles?.filter(
        (role) =>
          role.subdomain !== GATSBY_WEBSITE_URL ||
          role.subdomain !== GATSBY_TELECONSULT_URL
      ) || []
    let userAllowedSubdomains =
      userData?.allowedSubdomains?.filter(
        (subdomain) =>
          subdomain !== GATSBY_WEBSITE_URL ||
          subdomain !== GATSBY_TELECONSULT_URL
      ) || []

    if (!authUid) {
      userData = await checkIfUserAlreadyExists(authUser.email)
      authUid = userData?.authUid
    }

    let documents = [employeeId?.front]
    let uploadedDocumentUrls

    if (documents?.length) {
      uploadedDocumentUrls = await uploadDocumentsToStorage({
        documents,
        path: `/${userData.email}/employeeId`,
        docBaseName: `${moment().format("YYYYMMDD")}`,
        docType: "employeeid",
      })
    }

    // Add  address details
    let addressInformation = {
      primary: true,
      type: address?.addressType?.toLowerCase() || "home",
      streetAddress: address?.streetAddress,
      city: address?.city?.value || address?.city,
      province: address?.province?.value || address?.province,
      barangay: address?.barangay?.value || address?.barangay,
    }

    // Check if address exists
    let isAddressUnique = checkIfAddressIsUnique(
      addressInformation,
      addressesList
    )
    let finalAddressList = []
    if (isAddressUnique) {
      // Or remove primary from others, use new address as primary (since most recent enrollment --> most recent address)
      if (addressesList?.length > 0) {
        addressesList.forEach((address) => {
          address.primary = false
        })
      }
      finalAddressList = [...addressesList, { ...addressInformation }]
    } else {
      finalAddressList = [...addressesList]
    }

    let newAddress = await firebase
      .firestore()
      .collection("addresses")
      .add({ addresses: [...finalAddressList] })
    addressesId = newAddress.id

    let { month, date, year } = birthday

    const USER_DOCUMENT = {
      firstName: firstName || "",
      lastName: lastName || "",
      mobileNumber: mobileNumber || "",
      email: email || "",
      addresses: addressesId,
      company: company || "N/A",
      site: site || "N/A",
      birthday: {
        month: month.value || month,
        date: date.value || date,
        year: year.value || year,
      },
      emergencyContact: { ...emergencyContact },
      employeeNumber: employeeNumber,
      department: department,
      sex: gender || sex,
      roles: [
        ...userRoles,
        {
          status: "active",
          subdomain: GATSBY_WEBSITE_URL,
          role: GATSBY_FIREBASE_USER_ROLE_ID,
          projectId: GATSBY_FIREBASE_PROJECT_ID,
        },
        {
          status: "active",
          subdomain: GATSBY_TELECONSULT_URL,
          role: GATSBY_FIREBASE_USER_ROLE_ID,
          projectId: GATSBY_FIREBASE_PROJECT_ID,
        },
      ],
      allowedSubdomains: [
        ...userAllowedSubdomains,
        GATSBY_WEBSITE_URL,
        GATSBY_TELECONSULT_URL,
      ],
      accessCode,
      uploadedDocumentUrls,
    }
    let firebaseUser

    // User data will always be null
    if (userData?.id) {
      firebaseUser = await firebase
        .firestore()
        .collection("users")
        .doc(userData?.id)
        .update({ ...USER_DOCUMENT, authUid })
    } else {
      firebaseUser = await firebase
        .firestore()
        .collection("users")
        .add({ ...USER_DOCUMENT, authUid })
    }
    enrollmentData.email = email
    await axios.post(GATSBY_CREATE_ENROLLMENT_RECORD, {
      ...enrollmentData,
      ...USER_DOCUMENT,
      firebaseUserId: firebaseUser?.id,
      website: process.env.GATSBY_AIRTABLE_WEBSITE_NAME,
    })

    let newUserData = await getUserData({ authUid })
    let userAddresses = await getUserAddresses({ addressesId })

    if (isBrowser()) {
      sessionStorage.setItem("userData", JSON.stringify({ ...newUserData }))
      sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
    }

    await dispatch({ type: "RESET_DETAILS" })
    await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })

    if (callback) callback()
  } catch (error) {
    console.log(error)
    if (errorCallback) errorCallback()
  }
}
